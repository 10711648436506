import { AppBar, Avatar, Box, Button, Icon } from "@mui/material";
import { useStore } from "@nanostores/react";
import AvatarMenu from "common/components/AvatarMenu";
import { user } from "common/stores/UserStore";
import { Endorsement } from "common/utils/UserUtils";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import logo from "public/images/OPT-Primary-White.svg";
import DefaultAvatar from "public/images/default_avatar.png";
import React, { useState } from "react";
import styles from "./opteraAppBar.module.css";

export interface NavItem {
	id: number;
	label: string;
	path: string;
	icon?: React.ReactElement<typeof Icon>;
}

const baseNavItems: NavItem[] = [];

export interface OpteraAppBarProps {
	items: Array<NavItem>;
	onOrganizationChange?: () => void;
}

const OPTERA_CLASSIC_ENDORSEMENTS: Endorsement[] = [
	"sam:brand",
	"supplychain:brand",
	"supplychain:supplier",
];

/**
 * A Navigation header for Optera themed pages.
 */
function OpteraAppBar({ items = [], onOrganizationChange }: OpteraAppBarProps) {
	const $user = useStore(user);
	const router = useRouter();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	let navItems = [...baseNavItems, ...items];

	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const alreadyInOpteraClassic =
		router.pathname.startsWith("/legacyOverview") ||
		router.pathname.startsWith("/esgInsights") ||
		(router.pathname.startsWith("/supplyChain") &&
			!router.pathname.startsWith("/supplyChainManager"));
	const showOpteraClassicAccess =
		!alreadyInOpteraClassic &&
		OPTERA_CLASSIC_ENDORSEMENTS.some((el) => $user.endorsements.includes(el));

	return (
		<AppBar
			component="nav"
			position="static"
			className={styles.appBar}
			id="appBar-nav"
			elevation={0}
		>
			<Box className={styles.logo}>
				<Link href="/">
					<Image src={logo} width="112" height="24" alt="Optera Logo" />
				</Link>
			</Box>
			<Box
				sx={{ flexGrow: 2, alignItems: "left", mt: "2px" }}
				role="navigation"
				aria-label="Main navigation"
			>
				{navItems.map((item) => (
					<Link key={item.id} href={item.path}>
						<Button className={styles.navItem} aria-label={item.label}>
							{item.label}
						</Button>
					</Link>
				))}
			</Box>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				{showOpteraClassicAccess && (
					<Link
						href="/legacyOverview"
						aria-label="Open Classic Optera"
						className={styles.switchButton}
					>
						<Button
							tabIndex={-1}
							color="inherit"
							variant="outlined"
							size="large"
						>
							Open Classic Optera
						</Button>
					</Link>
				)}
				<Avatar
					src={$user.picture || DefaultAvatar.src}
					data-testid="Avatar"
					onClick={handleAvatarClick}
					tabIndex={0}
					role="button"
					aria-label="User menu"
					className={styles.avatarIcon}
				/>
			</Box>
			{$user.username && (
				<AvatarMenu
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					organization={$user.organization_id.toString()}
					onOrganizationChange={onOrganizationChange}
					aria-label="User menu options"
				/>
			)}
		</AppBar>
	);
}

export default OpteraAppBar;
