import { surveyInfo } from "common/stores/SurveyStore";
import {
	CampaignMetadata,
	Consumption,
	ConsumptionEstimateRequest,
	EnergyBreakdown,
	FacilityTypes,
	FormMetadata,
	GenericObject,
	NAICS_MAPPING,
	OperationalEstimateRequest,
	ReplyContent,
	ReplyContentIdentifier,
	SectionStatus,
	SubmitStatus,
	SurveyAnswers,
} from "common/types/Survey";
import { fuelTypesAll } from "modules/survey/FuelTypeForm";
import { AddBusinessUnitFormData } from "pages/survey/addBusinessUnit";
import { AddConsumptionFacilityFormData } from "pages/survey/addConsumptionFacility";
import { AddConsumptionFacilityCbamFormData } from "pages/survey/addConsumptionFacilityCbam";
import { AddFacilityFormData } from "pages/survey/addFacility";
import { AddFacilityCbamFormData } from "pages/survey/addFacilityCbam";
import { AddInitiativeFormData } from "pages/survey/addInitiative";
import { AddTargetFormData } from "pages/survey/addTarget";
import countries from "public/JSON/countries.json";

/** To help deal with the fact that synthetic events in React are a pain
 * React assumes event targets might be something other than DOM Nodes,
 * usually we know e.target can only be a HTMLElement or even something more specific like an HTMLFormElement
 * but this makes Typescript more sure about it
 */
export function assertIsNode(e: EventTarget | null): asserts e is Node {
	if (!e || !("nodeType" in e)) {
		throw new Error(`Node expected`);
	}
}
/** Check if object has any keys
 * specifically used to check if dirtyFields from RHF is empty rather than rely on RHF isDirty which was problematic
 */
export const isEmpty = (obj) => Object.keys(obj).length === 0;

/** Leverage the Mui-error class and some similar custom form components class names
 * as UI anchors to scroll to the topmost error on a page
 * Useful on tall forms where invalid items might be out of view.
 */
export function scrollToFormError() {
	let fieldErrs = document.getElementsByClassName("Mui-error");
	let tryFocus = true;
	if (fieldErrs.length == 0) {
		fieldErrs = document.getElementsByClassName("cardGroup-error");
		tryFocus = false; // while many Mui-error items have an input that makes sense to .focus() on, CardGroups do no
	}
	fieldErrs[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
	if (tryFocus) {
		// even with .focus({preventScroll: true}) jumps directly to the field nullifying the smooth scrollIntoView animation
		// setTimeout is best option until API provides some way to properly wait for scrollIntoView
		setTimeout(() => {
			fieldErrs[0]?.closest("div")?.querySelector("input")?.focus();
		}, 500);
	}
}

export function transformFacilityForApi(
	content_json:
		| AddFacilityFormData
		| AddConsumptionFacilityFormData
		| AddFacilityCbamFormData
		| AddConsumptionFacilityCbamFormData,
	content_identifier: ReplyContentIdentifier,
	metadata: GenericObject | undefined,
	reply_id: number,
	modified_by: string,
	modified_at?: string,
	created_by?: string,
	id?: number
): ReplyContent {
	return {
		content_json,
		created_by: created_by || modified_by,
		id,
		level: "enterprise", // will change to 'brand_specific' once BE allows that value
		content_identifier,
		modified_by,
		reply_id,
		form_metadata: metadata,
		third_party_metadata: {},
		modified_at,
	};
}

export function transformBusinessUnitForApi(
	content_json: AddBusinessUnitFormData,
	metadata: FormMetadata,
	reply_id: number,
	modified_by: string,
	modified_at?: string,
	created_by?: string,
	id?: number
): ReplyContent {
	return {
		content_json,
		created_by: created_by || modified_by,
		id,
		level: "enterprise", // will change to 'brand_specific' once BE allows that value
		content_identifier: "business_unit",
		modified_by,
		reply_id,
		form_metadata: metadata,
		third_party_metadata: {},
		modified_at,
	};
}

export function transformInitiativeForApi(
	content_json: AddInitiativeFormData,
	metadata: FormMetadata,
	reply_id: number,
	modified_by: string,
	modified_at?: string,
	created_by?: string,
	id?: number
): ReplyContent {
	return {
		content_json,
		created_by: created_by || modified_by,
		id,
		level: "enterprise", // will change to 'brand_specific' once BE allows that value
		content_identifier: "initiative",
		modified_by,
		reply_id,
		form_metadata: metadata,
		third_party_metadata: {},
		modified_at,
	};
}

export function transformTargetForApi(
	content_json: AddTargetFormData,
	metadata: FormMetadata,
	reply_id: number,
	modified_by: string,
	modified_at?: string,
	created_by?: string,
	id?: number
): ReplyContent {
	return {
		content_json,
		created_by: created_by || modified_by,
		id,
		level: "enterprise", // will change to 'brand_specific' once BE allows that value
		content_identifier: "target",
		modified_by,
		reply_id,
		form_metadata: metadata,
		third_party_metadata: {},
		modified_at,
	};
}

export function transformAnswersForApi(
	content_json: SurveyAnswers,
	content_identifier: ReplyContentIdentifier | undefined,
	metadata: FormMetadata | undefined,
	reply_id: number,
	modified_by: string,
	modified_at?: string,
	created_by?: string,
	id?: number
): ReplyContent {
	return {
		content_json,
		created_by: created_by || modified_by,
		id,
		level: "enterprise",
		content_identifier,
		modified_by,
		reply_id,
		form_metadata: metadata,
		third_party_metadata: {},
		modified_at,
	};
}

export function formatFacilityGrossConsumptions(
	facilityBreakdownOfGross: EnergyBreakdown[]
) {
	if (!facilityBreakdownOfGross || facilityBreakdownOfGross.length <= 0) {
		return [];
	}

	const facilityFuelConsumptions: Consumption[] = [];
	for (const fuelType of facilityBreakdownOfGross) {
		if (
			fuelType.fuelType &&
			fuelType.fuelConsumption &&
			Number(fuelType.fuelConsumption) > 0
		) {
			const units = fuelTypesAll?.[fuelType.fuelType]?.units;
			const fuelConsumption: Consumption = {
				energy_type: fuelType.fuelType,
				consumption_value: {
					magnitude: fuelType.fuelConsumption as string,
					units: units.replace(/ /g, "_").replace(/liters/gm, "liter"),
				},
			};
			facilityFuelConsumptions.push(fuelConsumption);
		}
	}
	return facilityFuelConsumptions;
}

export function formatFacilityConsumptionEstimate(
	facilityContent:
		| AddConsumptionFacilityFormData
		| AddConsumptionFacilityCbamFormData
) {
	const facilityElectricity =
		Number(
			facilityContent.facilityTotalElectricityConsumption ?? ("0" as string)
		) -
		Number(
			facilityContent.facilityTotalElectricityConsumptionRenewables ??
				("0" as string)
		);
	const facilityFuelConsumptions: Consumption[] =
		formatFacilityGrossConsumptions(facilityContent.facilityBreakdownOfGross);

	const countryCode =
		countries.find(
			(country) => country.label === facilityContent.facilityCountry
		)?.code || "";

	// because both of these become optional entries in addCbam case we fallback to doing area based estimation if consumption estimations would be 0
	if (!facilityElectricity && !facilityFuelConsumptions.length) {
		// do footprint based estimate
		const naicsCode: number | undefined = ![
			"office_building",
			"warehouse_building",
		].includes(facilityContent.facilityType)
			? Number(
					Object.keys(NAICS_MAPPING).find(
						(key) => NAICS_MAPPING[key] === facilityContent.facilityType
					)
				)
			: undefined;

		// NOTE currently expecting that facilityContent.facilityType as FacilityTypes works which may not be true given select options in form
		let facilityArea: OperationalEstimateRequest = {
			facility_type: naicsCode
				? "manufacturing_plant"
				: (facilityContent.facilityType as FacilityTypes),
			country: countryCode,
			year: 2022, // Ideally this would be surveyYear (i.e. Current Year - 1) but the emissions chains DB isn't setup for that. For now, the best/latest data we can get is 2022.
			area: {
				magnitude: Number(facilityContent.facilitySize) || 0,
				units: "sq_ft",
			},
		};

		if (naicsCode) {
			facilityArea.naics_code = naicsCode;
		}

		return [facilityArea];
	} else {
		// do consumption based estimate
		const facilityConsumption: ConsumptionEstimateRequest = {
			consumptions: [
				{
					energy_type: "electrical_energy",
					consumption_value: {
						magnitude: facilityElectricity.toString(),
						units: "MWh",
					},
				},
				...facilityFuelConsumptions,
			],
			year: 2022,
			country: countryCode,
		};

		return {
			[facilityContent.facilityName]: facilityConsumption,
		};
	}
}

export function formatGlobalConsumption(consumptionContent: SurveyAnswers) {
	const mainElectricity =
		Number(consumptionContent.totalElectricityConsumption ?? ("0" as string)) -
		Number(
			consumptionContent.renewableElectricityConsumption ?? ("0" as string)
		);
	const fuelConsumptions: Consumption[] = [];
	if (Array.isArray(consumptionContent.fuelTypesAndConsumptionList)) {
		for (const fuelType of consumptionContent.fuelTypesAndConsumptionList) {
			if (fuelType.fuelType && (fuelType.fuelConsumption as string) != "") {
				const fuelConsumption: Consumption = {
					energy_type: fuelType.fuelType,
					consumption_value: {
						magnitude: fuelType.fuelConsumption as string,
						units: fuelTypesAll[fuelType.fuelType]?.units
							.replace(/ /g, "_")
							.replace(/liters/gm, "liter"),
					},
				};
				fuelConsumptions.push(fuelConsumption);
			}
		}
	}

	const globalConsumption: ConsumptionEstimateRequest = {
		consumptions: [
			{
				energy_type: "electrical_energy",
				consumption_value: {
					magnitude: mainElectricity.toString(),
					units: "MWh",
				},
			},
			...fuelConsumptions,
		],
		year: 2022,
		country: "",
	};

	return { global: globalConsumption };
}

//EmissionsStickyHeader logic
export const defaultReportingStepsMap = [
	{
		route: "generalEmissionsReportingStep1",
		label: "Company Information",
		caption: "Required Section",
	},
	{
		route: "generalEmissionsReportingStep2",
		label: "Scope 1 Emissions",
		caption: "Required Section",
	},
	{
		route: "generalEmissionsReportingStep3",
		label: "Scope 2 Emissions",
		caption: "Required Section",
	},
	{
		route: "scope3Upstream",
		label: "Scope 3 Upstream Emissions",
		caption: "Required Section",
	},
	{
		route: "scope3Downstream",
		label: "Scope 3 Downstream Emissions",
		caption: "Required Section",
	},
	{
		route: "generalEmissionsReportingStep6",
		label: "Reporting & Verification",
		caption: "Required Section",
	},
	{
		route: "facilities",
		label: "Facility Emissions",
		caption: "Required Section",
	},
	{ route: "targets", label: "Emissions Targets", caption: "Required Section" },
	{
		route: "initiatives",
		label: "Emissions Initiatives",
		caption: "Required Section",
	},
];

export const reportingStepsMap = defaultReportingStepsMap.toSpliced(6, 0, {
	route: "businessUnits",
	label: "Business Unit Emissions",
	caption: "Required Section",
});

export const estimatorStepsMap = [
	{
		route: "estimator/industryAndRevenue",
		label: "",
		caption: "Required Section",
	},
	{
		route: "estimator/facilityTypes",
		label: "",
		caption: "Required Section",
	},
	{
		route: "estimator/facilitiesDetails",
		label: "",
		caption: "Required Section",
	},
	{
		route: "estimator/facilitiesOverview",
		label: "",
		caption: "Required Section",
	},
	{ route: "targets", label: "", caption: "Optional Section" },
	{
		route: "initiatives",
		label: "",
		caption: "Optional Section",
	},
];

export const consumptionStepsMap = [
	{
		route: "consumption/companyInformation",
		label: "",
		caption: "Required Section",
	},
	{
		route: "consumption/fuelTypes",
		label: "",
		caption: "Required Section",
	},
	{
		route: "consumption/energy",
		label: "",
		caption: "Required Section",
	},
	{
		route: "facilities",
		label: "",
		caption: "Required Section",
	},
	{ route: "targets", label: "", caption: "Optional Section" },
	{
		route: "initiatives",
		label: "",
		caption: "Optional Section",
	},
];

export function getReportingStepsMap() {
	const campaignMetadata = surveyInfo.get().campaign_metadata;
	if (!campaignMetadata) {
		return defaultReportingStepsMap;
	}
	return campaignMetadata?.hasBusinessUnits
		? reportingStepsMap
		: defaultReportingStepsMap;
}

//screenerQuestion logic
export const reportingFormMetadata = {
	surveySubmitStatus: "unfinished" as SubmitStatus,
	step1Status: "not started" as SectionStatus,
	step2Status: "not started" as SectionStatus,
	step3Status: "not started" as SectionStatus,
	scope3UpstreamStatus: "not started" as SectionStatus,
	scope3DownstreamStatus: "not started" as SectionStatus,
	step6Status: "not started" as SectionStatus,
	businessUnitStatus: "not started" as SectionStatus,
	facilityStatus: "not started" as SectionStatus,
	targetStatus: "not started" as SectionStatus,
	initiativeStatus: "not started" as SectionStatus,
};

export const defaultReportingFormMetadata = {
	surveySubmitStatus: "unfinished" as SubmitStatus,
	step1Status: "not started" as SectionStatus,
	step2Status: "not started" as SectionStatus,
	step3Status: "not started" as SectionStatus,
	scope3UpstreamStatus: "not started" as SectionStatus,
	scope3DownstreamStatus: "not started" as SectionStatus,
	step6Status: "not started" as SectionStatus,
	facilityStatus: "not started" as SectionStatus,
	targetStatus: "not started" as SectionStatus,
	initiativeStatus: "not started" as SectionStatus,
};

export function getReportingFormMetadataMap() {
	const campaignMetadata = surveyInfo.get().campaign_metadata;
	if (!campaignMetadata) {
		return defaultReportingFormMetadata;
	}

	let businessUnitsMap = campaignMetadata?.hasBusinessUnits
		? reportingFormMetadata
		: defaultReportingFormMetadata;

	return businessUnitsMap;
}

//stepperBar logic

export const defaultReportingMetadataMap = [
	"step1Status",
	"step2Status",
	"step3Status",
	"scope3UpstreamStatus",
	"scope3DownstreamStatus",
	"step6Status",
	"facilityStatus",
	"targetStatus",
	"initiativeStatus",
];

// copies defaultReportingMetadataMap inserting 1 item at index position 6
export const reportingMetadataMap = defaultReportingMetadataMap.toSpliced(
	6,
	0,
	"businessUnitStatus"
);

export const consumptionMetadataMap = [
	"companyInfoStatus",
	"fuelTypesStatus",
	"energyStatus",
	"facilityStatus",
	"targetStatus",
	"initiativeStatus",
];

export function getReportingMetadataMap() {
	const campaignMetadata = surveyInfo.get().campaign_metadata;
	if (!campaignMetadata) {
		return defaultReportingMetadataMap;
	}
	return campaignMetadata?.hasBusinessUnits
		? reportingMetadataMap
		: defaultReportingMetadataMap;
}

//surveyProgressMenu logic

export const reportingProgress = [
	{
		title: "Emissions Reporting",
		required: true,
		sections: [
			{ name: "Company Information", step: 0, metadataKey: "step1Status" },

			{ name: "Scope 1 Emissions", step: 1, metadataKey: "step2Status" },

			{ name: "Scope 2 Emissions", step: 2, metadataKey: "step3Status" },

			{
				name: "Scope 3 Upstream",
				step: 3,
				metadataKey: "scope3UpstreamStatus",
			},

			{
				name: "Scope 3 Downstream",
				step: 4,
				metadataKey: "scope3DownstreamStatus",
			},

			{
				name: "Reporting & Verification",
				step: 5,
				metadataKey: "step6Status",
			},
		],
	},
	{
		title: "Emissions Distributions",
		required: true,
		sections: [
			{
				name: "Business Unit Emissions",
				step: 6,
				metadataKey: "businessUnitStatus",
			},
			{ name: "Facility Emissions", step: 7, metadataKey: "facilityStatus" },
		],
	},
	{
		title: "Targets, Projects & Initiatives",
		required: true,
		sections: [
			{ name: "Emissions Targets", step: 8, metadataKey: "targetStatus" },
			{
				name: "Emissions Initiatives",
				step: 9,
				metadataKey: "initiativeStatus",
			},
		],
	},
];

export const defaultReportingProgress = [
	{
		title: "Emissions Reporting",
		required: true,
		sections: [
			{ name: "Company Information", step: 0, metadataKey: "step1Status" },

			{ name: "Scope 1 Emissions", step: 1, metadataKey: "step2Status" },

			{ name: "Scope 2 Emissions", step: 2, metadataKey: "step3Status" },

			{
				name: "Scope 3 Upstream",
				step: 3,
				metadataKey: "scope3UpstreamStatus",
			},

			{
				name: "Scope 3 Downstream",
				step: 4,
				metadataKey: "scope3DownstreamStatus",
			},

			{
				name: "Reporting & Verification",
				step: 5,
				metadataKey: "step6Status",
			},
		],
	},
	{
		title: "Emissions Distributions",
		required: true,
		sections: [
			{ name: "Facility Emissions", step: 6, metadataKey: "facilityStatus" },
		],
	},
	{
		title: "Targets, Projects & Initiatives",
		required: true,
		sections: [
			{ name: "Emissions Targets", step: 7, metadataKey: "targetStatus" },
			{
				name: "Emissions Initiatives",
				step: 8,
				metadataKey: "initiativeStatus",
			},
		],
	},
];

export const consumptionProgress = [
	{
		title: "Emissions Reporting",
		required: true,
		sections: [
			{
				name: "Company Information",
				step: 0,
				metadataKey: "companyInfoStatus",
			},

			{
				name: "Fuel Types & Consumption",
				step: 1,
				metadataKey: "fuelTypesStatus",
			},

			{ name: "Energy Reporting", step: 2, metadataKey: "energyStatus" },
		],
	},
	{
		title: "Emissions Distributions",
		required: true,
		sections: [
			{
				name: "Facility Emissions",
				step: 3,
				metadataKey: "facilityStatus",
			},
		],
	},
	{
		title: "Targets, Projects & Initiatives",
		required: true,
		sections: [
			{
				name: "Emissions Targets",
				step: 4,
				metadataKey: "targetStatus",
			},
			{
				name: "Emissions Initiatives",
				step: 5,
				metadataKey: "initiativeStatus",
			},
		],
	},
];

export function getReportingProgressMap() {
	const campaignMetadata = surveyInfo.get().campaign_metadata;
	if (!campaignMetadata) {
		return defaultReportingProgress;
	}
	return campaignMetadata?.hasBusinessUnits
		? reportingProgress
		: defaultReportingProgress;
}

//routing based on footer action logic

export const defaultReportingRoutes = [
	"/survey/screenerQuestion",
	"/survey/generalEmissionsReportingStep1",
	"/survey/generalEmissionsReportingStep2",
	"/survey/generalEmissionsReportingStep3",
	"/survey/scope3Upstream",
	"/survey/scope3Downstream",
	"/survey/generalEmissionsReportingStep6",
	"/survey/facilities",
	"/survey/targets",
	"/survey/initiatives",
	"/survey/submitSurvey",
];

// copies defaultReportingRoutes inserting new item(s)
export const reportingRoutes = defaultReportingRoutes.toSpliced(
	7,
	0,
	"/survey/businessUnits"
);

/**
 * for survey navigation where some steps may be not included depending on surveyor entity
 * @param url the page / route coming from
 * @param action what direction going in
 */
export function getNavRoute(url: string, action: "back" | "next" | "skip") {
	const campaignMetadata = surveyInfo.get().campaign_metadata;
	if (!campaignMetadata || !campaignMetadata?.hasBusinessUnits) {
		let index = defaultReportingRoutes.findIndex((i) => i === url);
		return action === "back"
			? defaultReportingRoutes[index - 1]
			: defaultReportingRoutes[index + 1];
	}
	if (campaignMetadata?.hasBusinessUnits) {
		let index = reportingRoutes.findIndex((i) => i === url);
		return action === "back"
			? reportingRoutes[index - 1]
			: reportingRoutes[index + 1];
	}
	return "";
}

// types documented here https://coda.io/d/_dd1ln-3sYT1/Campaign-types_suPO1Cb4#_luTYQmc4
export const getSurveyTypeFromMetadata = (metadata: CampaignMetadata) => {
	let type = "";
	if (!metadata) {
		type = "Other";
	} else if (
		metadata.addCBAM &&
		metadata.canAddFacilities &&
		metadata.hasBusinessUnits &&
		!metadata.isRBA
	) {
		type = "Emerson 2024"; // no longer in use but need the label
	} else if (
		!metadata.addCBAM &&
		!metadata.canAddFacilities &&
		!metadata.hasBusinessUnits &&
		metadata.isRBA
	) {
		type = "RBA";
	} else if (
		metadata.canAddFacilities &&
		metadata.hasBusinessUnits &&
		!metadata.addCBAM &&
		!metadata.isRBA
	) {
		type = "Comprehensive Emissions Reporting Survey";
	} else if (
		!metadata.canAddFacilities &&
		!metadata.hasBusinessUnits &&
		metadata.addCBAM &&
		!metadata.isRBA
	) {
		type = "CBAM Survey";
	} else if (
		!metadata.canAddFacilities &&
		!metadata.hasBusinessUnits &&
		!metadata.addCBAM &&
		!metadata.isRBA
	) {
		type = "Basic Emissions Survey";
	} else {
		type = "Other";
	}
	return type;
};

export interface SurveyTypeItem {
	hasBusinessUnits: boolean;
	canAddFacilities: boolean;
	isRBA: boolean;
	addCBAM: boolean;
}

export interface SurveyTypesMap {
	RBA: SurveyTypeItem;
	"Comprehensive Emissions Reporting Survey": SurveyTypeItem;
	"CBAM Survey": SurveyTypeItem;
	"Basic Emissions Survey": SurveyTypeItem;
}

// Available campaigns for creation
export const surveyTypesMap = {
	RBA: {
		hasBusinessUnits: false,
		canAddFacilities: false,
		addCBAM: false,
		isRBA: true,
	},
	"Comprehensive Emissions Reporting Survey": {
		hasBusinessUnits: true,
		canAddFacilities: true,
		addCBAM: false,
		isRBA: false,
	},
	"CBAM Survey": {
		hasBusinessUnits: false,
		canAddFacilities: false,
		addCBAM: true,
		isRBA: false,
	},
	"Basic Emissions Survey": {
		hasBusinessUnits: false,
		canAddFacilities: false,
		addCBAM: false,
		isRBA: false,
	},
};
