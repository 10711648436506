import {
	FormControl,
	FormControlProps,
	FormHelperText,
	Stack,
	SxProps,
	TextField,
	TextFieldProps,
	Theme,
} from "@mui/material";
import {
	Adornment,
	getAdornmentForInputProps,
} from "common/utils/AdornmentUtils";
import { forwardRef, Ref } from "react";
import styles from "./FormField.module.css";
// import { ControllerRenderProps } from "react-hook-form";
// TODO: FormTextFieldProps should extend ControllerRenderProps too
export interface FormTextFieldProps
	extends FormControlProps,
		Pick<
			TextFieldProps,
			| "type"
			| "multiline"
			| "size"
			| "label"
			| "disabled"
			| "error"
			| "inputProps"
		> {
	TextFieldSx?: SxProps<Theme>;
	value?: string | number;
	width?: string;
	height?: string;
	helperText?: string;
	helperTextColor?: "primary" | "secondary" | "warn" | "info" | "success";
	helperTextError?: string;
	placeholder?: string;
	focused?: boolean;
	adornment?: Adornment | Adornment[];
	name?: string;
	testid?: string;
	externalAdornment?: Adornment;
}
/**
 * a wrapper for a TextField that is primarily meant to be used within a form controlled by ReactHookForm
 */
function FormTextField(
	{
		label,
		value,
		width,
		height = "40px",
		disabled = false,
		error = false,
		helperText,
		helperTextColor,
		focused,
		helperTextError,
		size = "medium",
		multiline = false,
		adornment,
		placeholder,
		externalAdornment,
		type,
		testid,
		inputProps,
		TextFieldSx,
		...formControlProps
	}: FormTextFieldProps,
	ref: Ref<HTMLDivElement>
) {
	const inputId = `textField-${(formControlProps.name || "unknown").replace(
		" ",
		"-"
	)}`;

	let inputAdornment;
	if (Array.isArray(adornment)) {
		const adornments = { startAdornment: {}, endAdornment: {} };
		for (const adorn of adornment) {
			const tempAdornment = getAdornmentForInputProps(adorn, disabled);
			for (const key of Object.keys(tempAdornment)) {
				adornments[key] = tempAdornment[key];
			}
		}
		inputAdornment = adornments;
	} else {
		inputAdornment = adornment
			? getAdornmentForInputProps(adornment, disabled)
			: undefined;
	}

	return (
		<FormControl {...formControlProps}>
			<label
				htmlFor={inputId}
				className={styles.label + (disabled ? " disabled" : "")}
			>
				{label}
			</label>

			<Stack direction="row" spacing={0}>
				{externalAdornment && externalAdornment.position === "start" && (
					<div
						className={
							styles.externalAdornmentContainer +
							" start" +
							(multiline ? " multiline" : "")
						}
					>
						{externalAdornment.content}
					</div>
				)}

				<TextField
					disabled={disabled}
					error={error}
					multiline={multiline}
					value={value}
					id={inputId}
					minRows={3}
					placeholder={placeholder ? placeholder : ""}
					sx={{
						...TextFieldSx,
						width: width || "inherit",
						height: multiline ? "inherit" : height,
						// All this *might* be better in the OpteraTheme file
						// the only thing stopping me from doing that is that there are conditionals
						"& .Mui-disabled": {
							color: "var(--oc-palette-text-disabled)",
						},
						"& .MuiOutlinedInput-root": {
							height: multiline ? "inherit" : height,
							bgcolor: disabled
								? "var(--oc-palette-secondary-100)"
								: "var(--oc-palette-opteraBackground-main)",
							color: "var(--oc-palette-text-primary) !important",
							"& fieldset": {
								borderRadius:
									externalAdornment && externalAdornment.position == "start"
										? "0 8px 8px 0"
										: externalAdornment && externalAdornment.position == "end"
											? "8px 0 0 8px"
											: 1,
								border: "1px solid",
								borderColor: error
									? "var(--oc-palette-error-200)"
									: "var(--oc-palette-secondary-200)",
								padding: "8px 12px",
							},
							"&.Mui-focused fieldset": {
								boxShadow: error
									? "0px 0px 0px 4px var(--oc-palette-error-box-shadow)"
									: "0px 0px 0px 4px var(--oc-palette-primary-box-shadow)",
								borderColor: error
									? "var(--oc-palette-error-200)"
									: "var(--oc-palette-primary-500)",
							},
						},
					}}
					size={size}
					focused={focused}
					type={type}
					name={formControlProps.name}
					autoComplete="off"
					hiddenLabel={formControlProps.hiddenLabel}
					InputProps={{
						"data-testid": testid || formControlProps.name,
						...inputAdornment,
					}}
					inputProps={inputProps}
					ref={ref}
				/>
				{externalAdornment && externalAdornment.position === "end" && (
					<div
						className={
							styles.externalAdornmentContainer +
							" end" +
							(multiline ? " multiline" : "")
						}
					>
						{externalAdornment.content}
					</div>
				)}
			</Stack>

			{(helperTextError || helperText) && (
				<FormHelperText
					error={error}
					disabled={disabled}
					className={
						styles.helperText + (error ? " error" : ` ${helperTextColor}`)
					}
					sx={{
						maxWidth: width ?? "280px",
					}}
				>
					{error && helperTextError ? helperTextError : helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
}

export default forwardRef(FormTextField);
