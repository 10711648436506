import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Stack } from "@mui/material";
import FormSelect, { IMenuItem } from "common/components/FormSelect/FormSelect";
import NumberFormatInput from "common/components/FormTextField/NumberFormatInput";
import { EnergyBreakdown } from "common/types/Survey";
import {
	Controller,
	RegisterOptions,
	UseFieldArrayRemove,
	useWatch,
} from "react-hook-form";

export interface FuelTypeFormProps {
	textFieldLabel: string;
	selectLabel: string;
	control: any;
	fields: EnergyBreakdown[];
	remove: UseFieldArrayRemove;
	append: any;
	fieldName: string;
	disabled?: boolean;
	rules?: Exclude<
		RegisterOptions,
		"valueAsNumber" | "valueAsDate" | "setValueAs"
	>;
}

export const fuelTypesAll = {
	jeta: { name: "Aviation fuel (Jet A)", units: "liters" },
	aviationgas: { name: "Aviation gasoline", units: "liters" },
	biodiesel: { name: "Biodiesel (100% mineral diesel)", units: "liters" },
	kerosene: { name: "Burning oil (Kerosene)", units: "liters" },
	butane: { name: "Butane", units: "liters" },
	coaldomestic: { name: "Coal (domestic)", units: "metric tons" },
	coalelectric: { name: "Coal (electricity generation)", units: "metric tons" },
	coalindustrial: { name: "Coal (industrial)", units: "metric tons" },
	cokemass: { name: "Coal coke", units: "metric tons" },
	cng: { name: "Compressed natural gas (CNG)", units: "liters" },
	diesel: { name: "Diesel (average biofuel blend)", units: "liters" },
	gasoils: { name: "Gas oils", units: "liters" },
	lng: { name: "Liquefied natural gas (LNG)", units: "liters" },
	lpg: { name: "Liquefied petroleum gases (LPG)", units: "liters" },
	lubricants: { name: "Lubricants", units: "liters" },
	marinefueloil: { name: "Marine fuel oil", units: "liters" },
	marinegasoil: { name: "Marine gas oil", units: "liters" },
	naphtha: { name: "Naphtha", units: "liters" },
	natural_gas_thermal_energy: { name: "Natural gas", units: "MMBtu" },
	petrolhundred: { name: "Petrol (100% mineral petrol)", units: "liters" },
	petrolavg: { name: "Petrol (average biofuel blend)", units: "liters" },
	petroleumcokemass: { name: "Petroleum coke", units: "metric tons" },
	distillatefueloil: {
		name: "Processed fuel oils - distillate oil",
		units: "liters",
	},
	residualoil: { name: "Processed fuel oils - residual oil", units: "liters" },
	propaneliquid: { name: "Propane", units: "liters" },
	r134a: { name: "R-134a", units: "kg" },
	r404a: { name: "R-404a", units: "kg" },
	r410a: { name: "R410a", units: "kg" },
	wasteoils: { name: "Waste oils", units: "liters" },
};

const fuelTypeSelectItems: Array<IMenuItem> = [];
for (const key in fuelTypesAll) {
	fuelTypeSelectItems.push({
		text: fuelTypesAll[key].name,
		value: key,
	} as IMenuItem);
}

function FuelTypeForm({
	textFieldLabel,
	selectLabel,
	control,
	fields,
	remove,
	append,
	fieldName,
	disabled = false,
	rules = {},
}: FuelTypeFormProps) {
	const fuelTypeValues: EnergyBreakdown[] = useWatch({
		control,
		name: fieldName,
	});
	const limitedOptions = fuelTypeSelectItems.map((item) => {
		let hasTypeAlready = fuelTypeValues.some(
			(selectedFuelType) => item.value == selectedFuelType.fuelType
		);
		item.disabled = hasTypeAlready;
		return item;
	});

	return (
		<>
			{fields.map((item, index: number) => {
				return (
					<Stack
						direction="row"
						key={item.id}
						sx={{ mb: "32px" }}
						alignItems="start"
					>
						<Controller
							name={`${fieldName}.${index}.fuelType`}
							control={control}
							key={`ft-${index}-select`}
							rules={rules}
							disabled={disabled}
							render={({ field, fieldState: { error } }) => (
								<FormSelect
									menuItemList={limitedOptions}
									sx={{ width: "280px", mr: "16px" }}
									label={selectLabel}
									error={!!error}
									{...field}
								/>
							)}
						/>
						<Controller
							name={`${fieldName}.${index}.fuelConsumption`}
							control={control}
							key={`ft-${index}`}
							rules={rules}
							disabled={disabled}
							render={({ field, fieldState: { error } }) => (
								<NumberFormatInput
									thousandSeparator
									valueIsNumericString
									allowNegative={false}
									label={textFieldLabel}
									sx={{ width: "280px" }}
									adornment={{
										position: "end",
										content:
											fuelTypesAll?.[fuelTypeValues[index]?.fuelType]?.units,
									}}
									error={!!error}
									inputProps={{ autoComplete: "off", name: field.name }}
									{...field}
								/>
							)}
						/>
						{fields.length > 1 && (
							<Box sx={{ pt: "22px", pl: 2 }}>
								<IconButton
									aria-label="Remove Row"
									disabled={disabled}
									onClick={() => remove(index)}
								>
									<CloseIcon fontSize="large" />
								</IconButton>
							</Box>
						)}
					</Stack>
				);
			})}

			<Button
				type="button"
				color="secondary"
				variant="contained"
				onClick={() => {
					append({ fuelType: null, fuelConsumption: null });
				}}
				disabled={disabled}
			>
				Add Another Fuel Type
			</Button>
		</>
	);
}

export default FuelTypeForm;
