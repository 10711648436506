import { SurveyStatus } from "modules/survey/SurveyDataGrid";
import { AddBusinessUnitFormData } from "pages/survey/addBusinessUnit";
import { AddConsumptionFacilityFormData } from "pages/survey/addConsumptionFacility";
import { AddConsumptionFacilityCbamFormData } from "pages/survey/addConsumptionFacilityCbam";
import { AddFacilityFormData } from "pages/survey/addFacility";
import { AddFacilityCbamFormData } from "pages/survey/addFacilityCbam";
import { AddInitiativeFormData } from "pages/survey/addInitiative";
import { AddTargetFormData } from "pages/survey/addTarget";
import { BusinessUnitFormData } from "pages/survey/businessUnits";
import { CompanyInformationFormData } from "pages/survey/consumption/companyInformation";
import { EnergyFormData } from "pages/survey/consumption/energy";
import { FuelTypesFormData } from "pages/survey/consumption/fuelTypes";
import { CbamPrescreenFormData } from "pages/survey/estimator";
import { FacilitiesDetailsFormData } from "pages/survey/estimator/facilitiesDetails";
import { FacilityTypesFormData } from "pages/survey/estimator/facilityTypes";
import { IndustryAndRevenueFormData } from "pages/survey/estimator/industryAndRevenue";
import { FacilityFormData } from "pages/survey/facilities";
import { Step1FormData } from "pages/survey/generalEmissionsReportingStep1";
import { Step2FormData } from "pages/survey/generalEmissionsReportingStep2";
import { Step3FormData } from "pages/survey/generalEmissionsReportingStep3";
import { Step6FormData } from "pages/survey/generalEmissionsReportingStep6";
import { InitiativeFormData } from "pages/survey/initiatives";
import { Scope3DownstreamFormData } from "pages/survey/scope3Downstream";
import { Scope3UpstreamFormData } from "pages/survey/scope3Upstream";
import { ScreenerQuestionFormData } from "pages/survey/screenerQuestion";
import { TargetFormData } from "pages/survey/targets";

export type StringBool = "true" | "false" | "";

export type ScreenerSelection = "main" | "consumption" | "estimator" | "";

export type SectionStatus = "not started" | "incomplete" | "complete";

export type SubmitStatus = "unfinished" | "attempted submit" | "complete";

export interface FormMetadata {
	surveySubmitStatus?: SubmitStatus;
	targetStatus?: SectionStatus;
	initiativeStatus?: SectionStatus;
	step1Status?: SectionStatus;
	step2Status?: SectionStatus;
	step3Status?: SectionStatus;
	scope3UpstreamStatus?: SectionStatus;
	scope3DownstreamStatus?: SectionStatus;
	step6Status?: SectionStatus;
	businessUnitStatus?: SectionStatus;
	facilityStatus?: SectionStatus;
	companyInfoStatus?: SectionStatus;
	fuelTypesStatus?: SectionStatus;
	energyStatus?: SectionStatus;
}

export interface GlobalEstimate {
	scope1_estimate?: string | number;
	scope2_estimate?: string | number;
	overviewEstimate?: any[]; // TODO: Better type
}

export interface SurveyAnswers
	extends ScreenerQuestionFormData,
		Step1FormData,
		Step2FormData,
		Step3FormData,
		Scope3UpstreamFormData,
		Scope3DownstreamFormData,
		Step6FormData,
		BusinessUnitFormData,
		FacilityFormData,
		TargetFormData,
		InitiativeFormData,
		CbamPrescreenFormData,
		CompanyInformationFormData,
		FuelTypesFormData,
		EnergyFormData,
		GlobalEstimate,
		IndustryAndRevenueFormData,
		FacilityTypesFormData,
		FacilitiesDetailsFormData {}

export type FuelType =
	| "jeta"
	| "aviationgas"
	| "biodiesel"
	| "kerosene"
	| "butane"
	| "coaldomestic"
	| "coalelectric"
	| "coalindustrial"
	| "cokemass"
	| "cng"
	| "diesel"
	| "gasoils"
	| "lng"
	| "lpg"
	| "lubricants"
	| "marinefueloil"
	| "marinegasoil"
	| "naphtha"
	| "natural_gas_thermal_energy"
	| "petrolhundred"
	| "petrolavg"
	| "petroleumcokemass"
	| "distillatefueloil"
	| "residualoil"
	| "propaneliquid"
	| "r134a"
	| "r404a"
	| "r410a"
	| "wasteoils";
export interface EnergyBreakdown {
	fuelType: FuelType;
	fuelConsumption: string | number;
	source?: string;
	id?: string; //added by useFieldArray
}

export type KeyIndicator =
	| "totalNumber"
	| "totalMass"
	| "totalVolume"
	| "totalMarketValue"
	| "totalEnergy"
	| "totalChemical";
export interface KeyIndicatorBreakdown {
	keyIndicator: KeyIndicator;
	keyIndicatorAmount: string | number;
	id?: string; //added by useFieldArray
}

export type UniqueIdentifier =
	| "dunsNumber"
	| "GLN"
	| "ISIN"
	| "LEI"
	| "SEDOL"
	| "tickerSymbol";

export interface UniqueIdentifierBreakdown {
	uniqueIdentifier: UniqueIdentifier;
	identificationNumber: string | number;
	id?: string; //added by useFieldArray
}
export interface AluminumProcessBreakdown {
	productionProcess: string;
	totalMass: string | number;
	hasMeasuredPfcEmissions: StringBool;
	pfcEmissions?: string | number;
	pfcGwpSource?: string;
}

export type GenericObject = Record<string, any>; // Ideally Record<string, unknown>

export type ReplyContentIdentifier =
	| "facility"
	| "consumption_facility"
	| "business_unit"
	| "target"
	| "initiative"
	| "emissions_estimate"
	| "general_emissions"
	| "consumption_estimate";

// The current use of the ReplyContent interface for multiple use cases is leading to TypeScript issues.
// To resolve this, we should consider defining separate types/interfaces for the request and response of each CRUD endpoint, or potentially using a model class if necessary.
// Currently, this interface is used in scenarios where an `id` is required (e.g., responses, update requests) and where `id` is not yet defined (e.g., create requests).
// We could compose and extend these interfaces to maintain code reusability, similar to the approach used in the Inquire repository.
// Ideally, the TypeScript types should closely align with the models and schemas defined in the Python back-end.
export interface ReplyContent {
	level: "enterprise" | "brand_specific" | "facility";
	content_json?:
		| SurveyAnswers
		| AddFacilityFormData
		| AddFacilityCbamFormData
		| AddConsumptionFacilityFormData
		| AddConsumptionFacilityCbamFormData
		| AddBusinessUnitFormData
		| AddTargetFormData
		| AddInitiativeFormData
		| object;
	third_party_metadata?: GenericObject;
	form_metadata?: FormMetadata;
	content_identifier?: ReplyContentIdentifier;
	surveyor_ids?: number[];
	created_by: string;
	modified_at?: string;
	id?: number;
	reply_id: number;
	surveyor_id?: string;
	surveyor_names?: string[];
	modified_by: string;
}

export interface ConsumptionFacilityReplyContent extends ReplyContent {
	content_json: AddConsumptionFacilityFormData;
	level: "brand_specific";
	content_identifier: "facility";
}

export interface ConsumptionFacilityCbamReplyContent extends ReplyContent {
	content_json: AddConsumptionFacilityCbamFormData;
	level: "brand_specific";
	content_identifier: "facility";
}

export interface FacilityReplyContent extends ReplyContent {
	content_json: AddFacilityFormData;
	level: "brand_specific";
	content_identifier: "facility";
}
export interface FacilityCbamReplyContent extends ReplyContent {
	content_json: AddFacilityCbamFormData;
	level: "brand_specific";
	content_identifier: "facility";
}

export interface BusinessUnitReplyContent extends ReplyContent {
	content_json: AddBusinessUnitFormData;
	level: "brand_specific";
	content_identifier: "business_unit";
}

export interface TargetReplyContent extends ReplyContent {
	content_json: AddTargetFormData;
	level: "enterprise";
	content_identifier: "target";
}

export interface InitiativeReplyContent extends ReplyContent {
	content_json: AddInitiativeFormData;
	level: "enterprise";
	content_identifier: "initiative";
}

export interface ReplyContentSubmitResponse {
	status: string;
}

export interface DashboardResponse {
	surveyee_name: string;
	surveyee_email_address: string;
	survey_type: string;
	sent_on: string;
	status: SurveyStatus;
}

export interface CampaignSummaryStats {
	id: number;
	campaign_name: string;
	campaign_start: string;
	campaign_end: string;
	total_recipients: number;
	completed_surveys: number;
	percent_completion: number;
}

export interface Respondent {
	surveyee_id: string;
	surveyee_name: string;
	surveyee_email_address: string;
	status: string;
	reply_id: string;
}

export type FacilityTypes =
	| "manufacturing_plant"
	| "office_building"
	| "warehouse_building";

export interface CountrySelection {
	label: string;
	code: string;
}
export interface FacilityEstimateEntry {
	id?: string;
	country?: CountrySelection;
	quantity?: number;
	area?: number | string;
	error?: any[];
}
export interface FacilitiesEntriesObject {
	entries: FacilityEstimateEntry[];
	facility_type?: string; // would expect to be FacilityTypes, but is not
	display?: string;
	naics_code?: string; // union of Object.values(NAICS_MAPPING)
}
export interface FacilityDetailsEntryGroup {
	manufacturing_plant?: FacilitiesEntriesObject;
	office_building?: FacilitiesEntriesObject;
	warehouse_building?: FacilitiesEntriesObject;
	[key: string]: FacilitiesEntriesObject | undefined; // until we pin down the set of actual potential key names
}

export type Unit = "sq_ft" | "m**2";
export interface OperationalEstimateRequest {
	facility_type: FacilityTypes;
	naics_code?: number;
	country: string;
	year: number;
	area: {
		magnitude: number;
		units: Unit;
	};
}

export interface OperationalEstimateResponse {
	manufacturing_plant?: {
		[key: string]: {
			// typeof Object.keys(NAICS_MAPPING)
			[key: string]: OperationalEstimateEntry; // keys are country codes
		};
	};
	office_building?: {
		[key: string]: OperationalEstimateEntry; // keys are country codes
	};
	warehouse_building?: {
		[key: string]: OperationalEstimateEntry; // keys are country codes
	};
}

export interface SubFlowPageProps {
	newName?: string;
	id?: string | number;
}

export const NAICS_MAPPING = {
	325: "Chemicals",
	325120: "Industrial Gases",
	332: "Fabricated Metal Products",
	333: "Machinery",
	334: "Computer and Electronic Products: General",
	334413: "Computer and Electronic Products: Semiconductors & Related Devices",
	335: "Electrical Equipment, Appliances, and Components",
	336: "Transportation Equipment: General",
	336111: "Transportation Equipment: Automobiles",
};

export interface Consumption {
	energy_type: string;
	consumption_value: {
		magnitude: string;
		units: string;
	};
}

export interface OperationalEstimateEntry {
	scope1: number;
	scope2: number;
}

export interface ConsumptionEstimateRequest {
	consumptions: Consumption[];
	year: number;
	country?: string;
	facility_type?: string;
	naics_code?: number;
}
export interface ConsumptionEstimateResponse {
	[key: string]: {
		// facilityNames | 'global' ?
		scope1: number;
		scope2: number;
	};
}

export interface ThankYouEmailMap {
	dev: string;
	prod: string;
}
export interface CampaignMetadata {
	hasBusinessUnits: boolean;
	canAddFacilities: boolean;
	isRBA: boolean;
	addCBAM: boolean;
	start_time?: string;
	end_time?: string;
	thank_you_smart_email_map?: ThankYouEmailMap;
}

export interface SurveyResponse {
	// GET /survey/survey_campaign/{campaign_id} response
	campaign_name: string;
	orchestrator_id?: number;
	orchestrator_name?: string;
	campaign_status: "start" | string; // TODO: make this proper enum  or remove - not really used?
	esp_list_id: string;
	campaign_metadata?: CampaignMetadata;
	organization_id?: number;
	id: number;
	logo_object_key?: string;
	modified_at: string;
	created_at: string;
}

export interface CampaignCreateRequestBody {
	campaign_name: string;
	orchestrator_id?: number;
	orchestrator_name?: string;
	campaign_status: "start" | string; // TODO: make this proper enum  or remove - not really used?
	esp_list_id: string;
	organization_id: number;
	campaign_metadata?: CampaignMetadata;
}

export interface SurveyCampaignUpdateRequestBody {
	// API docs don't specify which pieces of the request body are required and which are optional.
	orchestrator_id?: number;
	campaign_name: string;
	orchestrator_name?: string;
	campaign_status: "start" | string; // TODO: make this proper enum  or remove - not really used?
	esp_list_id: string;
	organization_id?: number;
	campaign_metadata?: CampaignMetadata;
}
export interface ReplyAssociation {
	campaign_id: number;
	id: number;
	reply_content_ids: number[];
	reply_id: number;
	submitted_at: string;
	surveyee_email_address: string;
	surveyee_id: string;
	surveyee_name: string;
	surveyor_id: string;
	surveyor_name: string;
	status: "in-progress" | "not started" | "completed" | "";
	third_party_metadata: GenericObject;
	is_active: boolean; // shows whether the supplier's date is being actively used for the surveyor
}

export interface CreateCampaignResponse {
	success: boolean;
	created_id: string;
}

export interface ReplyContentParams {
	content_identifier: ReplyContentIdentifier;
	source: string;
}

export interface IndustryAverageEstimate {
	measure_computation: {
		estimate_emissions: {
			scope1_emission_intensity_mean: number;
			scope2_emission_intensity_mean: number;
		};
	};
	asset_revenue: string;
}
export interface ImportError {
	message: string;
	row: number;
	column: string;
}

export interface GeneralError {
	error_type: string; //tbd
	message: string;
}
export interface VerifyUploadResponse {
	general_errors: GeneralError[];
	is_valid: boolean;
	row_errors: ImportError[];
	stats: {
		duplicate_rows: number;
		existing_associations: number;
		invalid_emails: number;
		total_rows: number;
	};
}

export interface ImportErrorRows {
	message: string;
	row: number | string;
	column: string;
	id: string;
}

export interface ESPError {
	Code: string;
	EmailAddress: string;
	Message: string;
}
export interface ESPStats {
	total_new: number;
	total_existing: number;
	failure_count: number;
	errors: ESPError[];
}

export interface ESPStored extends ESPStats {
	campaign_id: number;
}
export interface BulkUploadResponse {
	processed: {
		associations_created: number;
		contents_created: number;
		esp_stats: ESPStats;
		replies_created: number;
		total: number;
		traits_created: number;
		unique_surveyors_added: number;
	};
	success: boolean;
}

export interface RetrieveUrlResponse {
	url: string;
}

export interface UpdateLogoResponse {
	logo_object_key: string;
	message: string;
}

export interface AggregateEmissionIntensityResponse {
	scope1_emission_intensity_max?: number;
	scope1_emission_intensity_mean?: number;
	scope1_emission_intensity_median?: number;
	scope1_emission_intensity_min?: number;
	scope1_emission_intensity_stdev?: number;
	scope2_emission_intensity_max?: number;
	scope2_emission_intensity_mean?: number;
	scope2_emission_intensity_median?: number;
	scope2_emission_intensity_min?: number;
	scope2_emission_intensity_stdev?: number;
	scope2lb_emission_intensity_max?: number;
	scope2lb_emission_intensity_mean?: number;
	scope2lb_emission_intensity_median?: number;
	scope2lb_emission_intensity_min?: number;
	scope2lb_emission_intensity_stdev?: number;
	scope2mb_emission_intensity_max?: number;
	scope2mb_emission_intensity_mean?: number;
	scope2mb_emission_intensity_median?: number;
	scope2mb_emission_intensity_min?: number;
	scope2mb_emission_intensity_stdev?: number;
	size: number;
}

export type CDPActivityGroup =
	| "Air transport"
	| "Bars, hotels & restaurants"
	| "Biotech & pharma"
	| "Cement & concrete"
	| "Chemicals"
	| "Coal mining"
	| "Commercial & consumer services"
	| "Construction"
	| "Convenience retail"
	| "Crop farming"
	| "Discretionary retail"
	| "Electrical & electronic equipment"
	| "Energy utility networks"
	| "Entertainment facilities"
	| "Financial services"
	| "Fish & animal farming"
	| "Food & beverage processing"
	| "Government bodies"
	| "Health care provision"
	| "Industrial support services"
	| "Intermodal transport & logistics"
	| "International bodies"
	| "IT & software development"
	| "Land & property ownership & development"
	| "Leisure & home manufacturing"
	| "Light manufacturing"
	| "Logging & rubber tapping"
	| "Marine transport"
	| "Media, telecommunications & data center services"
	| "Medical equipment & supplies"
	| "Metal products manufacturing"
	| "Metal smelting, refining & forming"
	| "Metallic mineral mining"
	| "Non-energy utilities"
	| "Nuclear power generation"
	| "Oil & gas extraction & production"
	| "Oil & gas processing"
	| "Oil & gas retailing"
	| "Oil & gas storage & transportation"
	| "Other materials"
	| "Other mineral mining"
	| "Other services"
	| "Paper products & packaging"
	| "Plastic product manufacturing"
	| "Powered machinery"
	| "Print & publishing services"
	| "Rail transport"
	| "Renewable energy equipment"
	| "Renewable power generation"
	| "Road transport"
	| "Specialized professional services"
	| "Textiles & fabric goods"
	| "Thermal power generation"
	| "Tobacco"
	| "Trading, wholesale, distribution, rental & leasing"
	| "Transportation equipment"
	| "Unknown"
	| "Waste power generation"
	| "Web & marketing services"
	| "Wood & paper materials"
	| "Wood & rubber products"
	| "";

export const CDP_ACTIVITY_GROUPS: { value: string; text: string }[] = [
	{
		value: "Air transport",
		text: "Air transport",
	},
	{
		value: "Bars, hotels & restaurants",
		text: "Bars, hotels & restaurants",
	},
	{
		value: "Biotech & pharma",
		text: "Biotech & pharma",
	},
	{
		value: "Cement & concrete",
		text: "Cement & concrete",
	},
	{
		value: "Chemicals",
		text: "Chemicals",
	},
	{
		value: "Coal mining",
		text: "Coal mining",
	},
	{
		value: "Commercial & consumer services",
		text: "Commercial & consumer services",
	},
	{
		value: "Construction",
		text: "Construction",
	},
	{
		value: "Convenience retail",
		text: "Convenience retail",
	},
	{
		value: "Crop farming",
		text: "Crop farming",
	},
	{
		value: "Discretionary retail",
		text: "Discretionary retail",
	},
	{
		value: "Electrical & electronic equipment",
		text: "Electrical & electronic equipment",
	},
	{
		value: "Energy utility networks",
		text: "Energy utility networks",
	},
	{
		value: "Entertainment facilities",
		text: "Entertainment facilities",
	},
	{
		value: "Financial services",
		text: "Financial services",
	},
	{
		value: "Fish & animal farming",
		text: "Fish & animal farming",
	},
	{
		value: "Food & beverage processing",
		text: "Food & beverage processing",
	},
	{
		value: "Government bodies",
		text: "Government bodies",
	},
	{
		value: "Health care provision",
		text: "Health care provision",
	},
	{
		value: "IT & software development",
		text: "IT & software development",
	},
	{
		value: "Industrial support services",
		text: "Industrial support services",
	},
	{
		value: "Intermodal transport & logistics",
		text: "Intermodal transport & logistics",
	},
	{
		value: "International bodies",
		text: "International bodies",
	},
	{
		value: "Land & property ownership & development",
		text: "Land & property ownership & development",
	},
	{
		value: "Leisure & home manufacturing",
		text: "Leisure & home manufacturing",
	},
	{
		value: "Light manufacturing",
		text: "Light manufacturing",
	},
	{
		value: "Logging & rubber tapping",
		text: "Logging & rubber tapping",
	},
	{
		value: "Marine transport",
		text: "Marine transport",
	},
	{
		value: "Media, telecommunications & data center services",
		text: "Media, telecommunications & data center services",
	},
	{
		value: "Medical equipment & supplies",
		text: "Medical equipment & supplies",
	},
	{
		value: "Metal products manufacturing",
		text: "Metal products manufacturing",
	},
	{
		value: "Metal smelting, refining & forming",
		text: "Metal smelting, refining & forming",
	},
	{
		value: "Metallic mineral mining",
		text: "Metallic mineral mining",
	},
	{
		value: "Non-energy utilities",
		text: "Non-energy utilities",
	},
	{
		value: "Nuclear power generation",
		text: "Nuclear power generation",
	},
	{
		value: "Oil & gas extraction & production",
		text: "Oil & gas extraction & production",
	},
	{
		value: "Oil & gas processing",
		text: "Oil & gas processing",
	},
	{
		value: "Oil & gas retailing",
		text: "Oil & gas retailing",
	},
	{
		value: "Oil & gas storage & transportation",
		text: "Oil & gas storage & transportation",
	},
	{
		value: "Other materials",
		text: "Other materials",
	},
	{
		value: "Other mineral mining",
		text: "Other mineral mining",
	},
	{
		value: "Other services",
		text: "Other services",
	},
	{
		value: "Paper products & packaging",
		text: "Paper products & packaging",
	},
	{
		value: "Plastic product manufacturing",
		text: "Plastic product manufacturing",
	},
	{
		value: "Powered machinery",
		text: "Powered machinery",
	},
	{
		value: "Print & publishing services",
		text: "Print & publishing services",
	},
	{
		value: "Rail transport",
		text: "Rail transport",
	},
	{
		value: "Renewable energy equipment",
		text: "Renewable energy equipment",
	},
	{
		value: "Renewable power generation",
		text: "Renewable power generation",
	},
	{
		value: "Road transport",
		text: "Road transport",
	},
	{
		value: "Specialized professional services",
		text: "Specialized professional services",
	},
	{
		value: "Textiles & fabric goods",
		text: "Textiles & fabric goods",
	},
	{
		value: "Thermal power generation",
		text: "Thermal power generation",
	},
	{
		value: "Tobacco",
		text: "Tobacco",
	},
	{
		value: "Trading, wholesale, distribution, rental & leasing",
		text: "Trading, wholesale, distribution, rental & leasing",
	},
	{
		value: "Transportation equipment",
		text: "Transportation equipment",
	},
	{
		value: "Unknown",
		text: "Unknown",
	},
	{
		value: "Waste power generation",
		text: "Waste power generation",
	},
	{
		value: "Web & marketing services",
		text: "Web & marketing services",
	},
	{
		value: "Wood & paper materials",
		text: "Wood & paper materials",
	},
	{
		value: "Wood & rubber products",
		text: "Wood & rubber products",
	},
];
