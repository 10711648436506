import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { useStore } from "@nanostores/react";
import FormSelect from "common/components/FormSelect/FormSelect";
import OpteraModal from "common/components/Modal";
import { user } from "common/stores/UserStore";
import EnergieClient, {
	GWP_VERSIONS,
	OrgPreferences,
	useOrganizationPreferences,
	useOrgTagTraits,
} from "modules/facade/EnergieClient";
import { useEffect } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";

interface EditOrgPreferencesModalProps {
	onClose: () => void;
	open: boolean;
}

const onInvalidSubmit: SubmitErrorHandler<OrgPreferences> = (data) => {
	console.warn("Invalid Submit", { data });
};

// interface OrgPreferencesForm extends OrgPreferences {
// 	temp_facility_grouping: string[];
// }

// {
// 	...preferences,
// 	temp_facility_grouping:
// 		preferences?.facility_grouping?.map((pref) => pref.trait) ?? [],
// },

function EditOrgPreferencesModal({
	onClose,
	open,
}: EditOrgPreferencesModalProps) {
	const $user = useStore(user);
	const { data: preferences } = useOrganizationPreferences();
	const { data: availableFacilityGroupings } = useOrgTagTraits();
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: preferences });

	useEffect(() => {
		reset(preferences);
	}, [preferences, reset]);

	const onValidSubmit: SubmitHandler<OrgPreferences> = async (data) => {
		// data.facility_grouping = data.temp_facility_grouping.map((traitString) => ({
		// 	trait: traitString,
		// }));
		// delete data.temp_facility_grouping;
		try {
			await EnergieClient.patchOrganizationPreferences(data);
			onClose();
			// bit severe, but because many responses from APIs that affect content of many pages and items in global stores could differ based on settings changes this is merited
			window.location.reload();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<OpteraModal
			title="Edit Organization Preferences"
			themeColor="primary"
			ariaPrefix="edit-organization-preferences"
			open={open}
			onClose={onClose}
			actionButton={
				<Button
					variant="contained"
					form="edit-organization-preferences"
					type="submit"
				>
					Submit
				</Button>
			}
		>
			<form
				id="edit-organization-preferences"
				onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}
				className="flex-col"
			>
				<p className="body1 mb-1">Edit your organization&apos;s preferences.</p>
				{Object.keys(errors).length !== 0 && (
					<p className="body2 mt-1 text-error">
						Please complete all required questions.
					</p>
				)}
				<Controller
					name="inventory_start_year"
					control={control}
					rules={{
						required: {
							value: true,
							message: "This is a required field.",
						},
					}}
					render={({ field, fieldState: { error } }) => {
						const currentYear = new Date().getFullYear();
						const startYear = 2000; // The earliest selectable year, as determined by product.

						const yearList = Array.from(
							{ length: currentYear - startYear + 1 },
							(_, i) => {
								const year = startYear + i;
								return {
									text: `${year}`,
									value: `${year}`,
								};
							}
						);

						return (
							<FormSelect
								label="Inventory Start Year"
								menuItemList={yearList}
								className="mb-2"
								noBlankOption
								error={!!error}
								{...field}
							/>
						);
					}}
				/>
				<Controller
					name="inventory_start_month"
					control={control}
					rules={{
						required: {
							value: true,
							message: "This is a required field.",
						},
					}}
					render={({ field, fieldState: { error } }) => (
						<FormSelect
							label="Inventory Start Month"
							menuItemList={[
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							].map((v, i) => ({ text: v, value: `${i + 1}` }))}
							noBlankOption
							error={!!error}
							{...field}
						/>
					)}
				/>
				<p className="subtitle2 mt-3 mb-2">GWP Version</p>
				<Controller
					name="gwp_version"
					control={control}
					rules={{
						required: {
							value: true,
							message: "This is a required field.",
						},
					}}
					render={({ field, fieldState: { error } }) => (
						<FormSelect
							label="GWP Version"
							menuItemList={GWP_VERSIONS.map((v) => ({ text: v }))}
							noBlankOption
							error={!!error}
							{...field}
						/>
					)}
				/>

				{$user.feature_flags.includes("esg-tag-grouping") ? (
					<>
						<p className="subtitle2 mt-3 mb-2">Facility Grouping Traits</p>
						<Controller
							name="facility_grouping"
							control={control}
							// rules={{
							// 	required: {
							// 		value: true,
							// 		message: "This is a required field.",
							// 	},
							// }}
							render={({
								field: { onChange, value, onBlur, name },
								fieldState: { error },
							}) => {
								let options = [];
								availableFacilityGroupings?.forEach((t) => {
									if (t?.trait) options.push(t?.trait);
								});
								return (
									<Autocomplete
										multiple
										id="tags-filled"
										options={options}
										size="small"
										value={Array.isArray(value) ? value : []}
										onChange={(e, data) => onChange(data)}
										renderTags={(value: readonly string[], getTagProps) =>
											!Array.isArray(value)
												? undefined
												: value.map((option: string, index: number) => {
														const { key, ...tagProps } = getTagProps({ index });
														return (
															<Chip label={option} key={key} {...tagProps} />
														);
													})
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label=""
												error={!!error}
												onBlur={onBlur}
												name={name}
											/>
										)}
									/>
								);
							}}
						/>
					</>
				) : undefined}
			</form>
		</OpteraModal>
	);
}

export default EditOrgPreferencesModal;
