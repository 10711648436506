// lib/initNumeral.ts
import numeral from "numeral";

let existingLocale = null;
try {
	existingLocale = numeral.localeData("si");
} catch (e) {
	if (e.message !== "Unknown locale : si") throw e;
}
// Only register if not already registered
if (!existingLocale) {
	// Retrieve default locale to extend from
	const defaultLocale = numeral.localeData("en");
	const siLocale = {
		delimiters: defaultLocale.delimiters,
		abbreviations: {
			...defaultLocale.abbreviations,
			thousand: "k",
			million: "M",
			billion: "G",
			trillion: "T",
		},
		ordinal: defaultLocale.ordinal,
		currency: defaultLocale.currency,
	};

	numeral.register("locale", "si", siLocale);
	numeral.locale("si");
}
