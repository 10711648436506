import { FormControlProps } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import FormTextField, { FormTextFieldProps } from "./FormTextField";
import React, { forwardRef, Ref } from "react";

export type INumberFormatInputProps = NumericFormatProps &
	FormTextFieldProps &
	Omit<FormControlProps, "children"> & {
		onChange?: (event: { target: { name: string; value: string } }) => void;
		value?: string | number;
		name?: string;
		maxLimit?: number;
	};

function NumberFormatInput(
	{
		onChange,
		name,
		error = false,
		inputProps,
		maxLimit = null,
		...props
	}: INumberFormatInputProps,
	ref: Ref<HTMLDivElement>
) {
	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		let value = parseFloat(event.target.value);
		if (maxLimit && !isNaN(value)) {
			value = Math.min(maxLimit, Math.max(0, value));
			onChange({ target: { name, value: value.toString() } });
		}
	};

	return (
		<NumericFormat
			inputProps={{
				inputMode: "decimal",
				autoComplete: "off",
				name: name,
				"data-testid": name,
				disabled: props.disabled,
				onBlur: handleBlur,
				ref,
				...inputProps,
			}}
			{...props}
			customInput={FormTextField}
			type="text"
			error={error}
			name={name}
			onValueChange={({ value: v }) => onChange({ target: { name, value: v } })}
		/>
	);
}

export default forwardRef(NumberFormatInput);
