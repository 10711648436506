import { persistentAtom } from "@nanostores/persistent";
import { setContext } from "@sentry/browser";
import { user } from "common/stores/UserStore";
import {
	ESPStored,
	FormMetadata,
	ReplyAssociation,
	ReplyContent,
	ReplyContentIdentifier,
	SurveyAnswers,
	SurveyResponse,
} from "common/types/Survey";
import { transformAnswersForApi } from "common/utils/FormUtils";
import InquireClient from "modules/facade/InquireClient";
import { atom, computed, map } from "nanostores";
import { msg } from "./SnackbarStore";

export const activeReplyId = persistentAtom("activeReplyId", null, {
	encode: JSON.stringify,
	decode: JSON.parse,
});
export const dashboardCampaignId = atom<string | number>(0);
export const dashboardCampaignIds = atom<number[] | undefined>(undefined);
export const currentCampaignTitle = persistentAtom<string | null>(
	"campaignTitle",
	null,
	{
		encode: JSON.stringify,
		decode: JSON.parse,
	}
);
export const replyIds = atom<number[]>([]);
export const hasMultipleCampaigns = computed(replyIds, (ids) => {
	return ids.length > 1;
});
export const espStats = atom<ESPStored>({
	campaign_id: 0,
	total_new: 0,
	total_existing: 0,
	failure_count: 0,
	errors: [],
});

export const replyAssociation = atom<ReplyAssociation>({
	campaign_id: 0,
	id: 0,
	reply_content_ids: [],
	reply_id: 0,
	submitted_at: "",
	surveyee_email_address: "",
	surveyee_id: "",
	surveyee_name: "",
	surveyor_id: "",
	surveyor_name: "",
	third_party_metadata: {},
	status: "",
	is_active: true,
});

export const surveyReplyContents = atom<Array<ReplyContent>>([]);

export const surveyors = atom<Array<string>>([]);

// only displayed in survey view - assumes we only allow users to enter the survey when filling out previous calendar year
export const surveyYear = new Date().getFullYear() - 1;

export const surveyInfo = map<SurveyResponse>({
	campaign_name: "",
	orchestrator_name: "",
	campaign_status: "",
	esp_list_id: "",
	id: 0,
	orchestrator_id: 0,
	created_at: "",
	modified_at: "",
	campaign_metadata: null,
});

const coreFinder = (item: ReplyContent) =>
	item.content_identifier &&
	["consumption_estimate", "emissions_estimate", "general_emissions"].includes(
		item.content_identifier
	);

export const coreSurveyAnswers = computed(
	surveyReplyContents,
	(replyContents) => {
		const coreReply = replyContents.find(coreFinder);
		// The `content_json` appears to be directly passed to some component props.
		// It seems to typically contain form data, and the backend returns it as-is.
		// We should consider modeling this data more effectively to ensure better structure and validation.
		return (coreReply?.content_json || {
			hasReportableData: "",
		}) as SurveyAnswers;
	}
);

export const formMetadata = computed(surveyReplyContents, (replyContents) => {
	if (replyContents.length == 0) {
		return {};
	}
	const coreReply = replyContents.find(coreFinder);
	return (coreReply?.form_metadata || {}) as FormMetadata;
});

export const contentIdentifier = computed(
	surveyReplyContents,
	(replyContents) => {
		const coreReply = replyContents.find(coreFinder);
		return coreReply?.content_identifier;
	}
);

export const submitStatus = computed(
	[surveyReplyContents, replyAssociation],
	(replyContents, replyAssoc) => {
		const coreReply = replyContents.find(coreFinder);
		return coreReply?.modified_at && replyAssoc.submitted_at
			? coreReply?.modified_at > replyAssoc.submitted_at
			: false;
	}
);

export async function getCampaignData(replyId: number) {
	InquireClient.getSurveyInfo(replyId)
		.then((surveyResponse) => {
			surveyInfo.set({
				...surveyResponse,
			});
			setContext("Survey", { ...surveyResponse });
		})
		.catch((e) => {
			console.error("Could not retrieve survey end time", e);
		});
	InquireClient.getSurveyors(replyId)
		.then((surveyorsResponse) => {
			surveyors.set(surveyorsResponse);
		})
		.catch((e) => {
			console.error("Could not retrieve surveyors", e);
		});

	await InquireClient.getReplyAssociations(replyId)
		.then((replyResp) => {
			if (replyResp?.length)
				replyAssociation.set(replyResp[0] as ReplyAssociation);
		})
		.catch(() => {
			console.error("Could not retrieve reply");
		});

	await InquireClient.getContents(replyId)
		.then((contents) => {
			surveyReplyContents.set(contents.items);
		})
		.catch((e) => {
			window.location.replace("/survey/accessFail");
			console.error(
				"May have incorrect organization selected or have a bad reply id",
				replyId,
				e
			);
			msg.set({
				message: `Failed to start survey reply for ${replyId}`,
				autoHide: false,
				severity: "error",
			});
		});
}

export const setAnswers = async (
	answers: SurveyAnswers,
	contentType: ReplyContentIdentifier | undefined,
	metadata?: FormMetadata
): Promise<void> => {
	const oldContent = surveyReplyContents
		.get()
		.find(
			(element: ReplyContent) => element.content_identifier === contentType
		);
	const newContent = transformAnswersForApi(
		answers,
		contentType,
		metadata,
		activeReplyId.get(),
		user.get().username,
		oldContent?.modified_at,
		oldContent?.created_by,
		oldContent?.id
	);
	if (oldContent) {
		await InquireClient.updateContent(
			activeReplyId.get(),
			oldContent,
			newContent
		)
			.then((replyContentResp) => {
				let updatedSurveyContents = surveyReplyContents
					.get()
					.map((rc) => (rc.id !== replyContentResp.id ? rc : replyContentResp));
				surveyReplyContents.set(updatedSurveyContents);
			})
			.catch((e) => {
				console.error("PUT replyContent failed", e);
				// TODO: need to pass some sort of message on to the UI here...
				// msg.set(...)
			});
		return;
	}
	await InquireClient.createContent(newContent, activeReplyId.get())
		.then((newContentItem) => {
			surveyReplyContents.set([...surveyReplyContents.get(), newContentItem]);
		})
		.catch((e) => {
			console.error("PUT replyContent failed", e);
			// TODO: need to pass some sort of message on to the UI here...
			// msg.set(...)
		});
};
